import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header/>

    <div class="section">
      <div class="container">
        <div class="one-half column">
        <h1>Page not found</h1>
        <p>Oops, looks like you have arrived at a page that does not exist.</p>
        <p>
        <Link to="/">Go back to the homepage</Link>
        </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
